@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 900;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ Black.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 800;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ ExtraBold.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 700;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ Bold.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 600;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 500;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ Medium.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 400;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ Regular.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 300;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ Light.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 200;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ ExtraLight.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 100;
  src: url("../fonts/montserrat-arabic/Montserrat-Arabic\ Thin.ttf");
}

* {
  font-family: "Montserrat Arabic", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
}

p {
  margin: 0;
}

center {
  width: 100%;
}

html,
body {
  background: #f2f2f4;
  line-height: 1.5;
  color: #0e072f;
}

input::-moz-placeholder {
  color: #4f4a68;
}

input:-ms-input-placeholder {
  color: #4f4a68;
}

input::-webkit-input-placeholder {
  color: #4f4a68;
}
